import React from 'react'
import { graphql } from 'gatsby'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'

import Layout from '../layout/mainLayout'
import Head from '../components/utils/head'

export const query = graphql`
query ($slug: String!){
  contentfulProject (slug: {eq: $slug}){
    projectName
    projectType
    tech
    websiteLink
    image {
      file {
        url
      }
    }
    projectContent{
      json
    }
  }
}
`

// const alt = node.data.target.fields.title['en-US']
const Project = (props) => {

  const options = {
    renderNode: {
      "embedded-asset-block": (node) => {
        const url = node.data.target.fields.file['en-US'].url
        return (
          <div className="project-content-img">
            <img className="project-content-img"  src={url}/>
          </div>
        )
      }
    }
  }

  const CreateBtn = () =>{
    if (props.data.contentfulProject.websiteLink){
      return 
    } 
  }

  // const divStyle = {
  //   width: '500px'
  // };

  return ( 
    <Layout>
    <Head />
     <div className="project-main">
       <div className="project-hero-section-wrapper">
         <div className="project-hero-section">
          <div className="project-hero-section__title">
            <h1>{props.data.contentfulProject.projectName}</h1>
            <p>{props.data.contentfulProject.projectType}</p>
            <hr/>
            
            <p  className="visit-website"><a target="blank" href={props.data.contentfulProject.websiteLink}> Website &#8594; </a></p>
          </div> 
          <div className="project-hero-section-img">
          <img className="project-hero-section-img__inner" src={props.data.contentfulProject.image.file.url} alt={props.data.contentfulProject.image.file.name}/>

          </div>
         </div>
       </div>
       <div className="project-content">
      {documentToReactComponents(props.data.contentfulProject.projectContent.json, options)}   
       </div>
     </div>
    </Layout>
  )
}

export default Project
